import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import usePageTitle from '../../services/hooks/usePageTitle';
import Appointment from '../Appointment/Appointment';

const Change: FC = () => {
  const { disableStepsOnRight } = useContext(StepsUpdateContext);

  const [ areStepsDisabled, setAreStepsDisabled ] = useState(false);
  const { t } = useTranslation();

  usePageTitle(t('pageTitle:change'));

  useEffect(() => {
    if (!areStepsDisabled){
      disableStepsOnRight();
      setAreStepsDisabled(true);
    }
  }, [areStepsDisabled, disableStepsOnRight]);

  return (
    <Appointment title={t('appointment:editTitle')} />
  );
};

export default Change;
