import { FC, ReactElement } from 'react';

import styles from './Tag.module.scss';

interface TagProps {
  text: string;
  icon?: ReactElement;
}

export const Tag: FC<TagProps> = ({ text, icon }): ReactElement => {
  return (
    <div className={styles.container}>
      <span>{text}</span>
      {icon}
    </div>
  );
};
