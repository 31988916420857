import { MultiLingualStylesheet, Stylesheet } from '../interfaces/Stylesheet';
import { CdnRepository } from '../api/interfaces/CdnRepository';
import CdnRepositoryRemote from '../api/CdnRepositoryRemote';

export default class StylesheetService {
  private repository: CdnRepository;

  constructor() {
    this.repository = new CdnRepositoryRemote();
  }

  public async getStylesheet(subscriptionId: string): Promise<MultiLingualStylesheet> {
    const stylesheet = await this.repository.getStylesheet(subscriptionId);

    return stylesheet;
  }

  public async getGlobalStylesheet(): Promise<MultiLingualStylesheet> {
    return await this.getStylesheet(
      import.meta.env.VITE_GLOBAL_STYLESHEET
    );
  }
}

export const getActiveStylesheet = (
  globalStylesheet: MultiLingualStylesheet,
  brandStylesheet: MultiLingualStylesheet | undefined,
  language: keyof MultiLingualStylesheet
): Stylesheet => {
  return brandStylesheet?.[language] || globalStylesheet[language];
};
