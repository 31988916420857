import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import StepsService from '../../services/StepsService';
import { TicketContext } from '../../contexts/TicketContext';
import { FCWithChildren } from '../../interfaces/Shared';
import { StepsContext } from '../Steps/StepsContext';
import { SupportedErrorTypes } from '../../services/ErrorService';

export const PageGuard: FCWithChildren = ({ children }) => {
  const { ticket } = useContext(TicketContext);
  const { steps } = useContext(StepsContext);
  const navigate = useNavigate();

  const excludedPaths = useMemo(() => ([
    StepsService.getStepUri(ticket?.hashId, 'contact'),
    StepsService.getStepUri(ticket?.hashId, 'faq'),
  ]), [ticket]);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (excludedPaths.includes(pathname)) {
      return;
    }

    const attemptedStepIndex = steps.findIndex(step => pathname.includes(step.id));

    if (ticket) {
      if (steps[attemptedStepIndex]?.isDisabled) {
        const activeStep = StepsService.getActiveStep(steps);

        navigate(activeStep.uri, { replace: true });
      }
    } else {
      navigate(`/error/${SupportedErrorTypes.ticketNotFound}`);
    }
  }, [ticket, navigate, steps, excludedPaths]);

  return children;
};
