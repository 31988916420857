import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from '../translations';
import { EnabledLanguage } from '../interfaces/Language';

export const defaultLanguage: EnabledLanguage = 'nl';

export const defaultNS = 'translation';

const languageDetector = new LanguageDetector(null, {
  // Caches and order params can be removed when we enable the language selector again
  caches: [], // Dont cache, since it needs to be checked every time
  order: ['querystring', 'navigator', 'htmlTag'], // Remove all storage options in case it was set before
});

const setHtmlLang = (lng: string) => {
  document.documentElement.setAttribute('lang', lng);
};

// Needs to be set before init, otherwise it will not set the initial language
i18n.on('languageChanged', setHtmlLang);

i18n.use(
  languageDetector
).use(
  initReactI18next
).init({
  resources: translations,
  fallbackLng: {
    default: ['nl'],
  },
  nonExplicitSupportedLngs: true, // This ensures that for example 'en-US' falls back to 'en'
  supportedLngs: ['nl', 'en'],
  keySeparator: ':',
});

export default i18n;
