import {
  createRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Appointment.module.scss';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { Ticket } from '../../interfaces/Ticket';
import { SlotsDictionary, TopSlot } from '../../interfaces/TopSlot';

import NavigationButton from '../../components/Navigation/NavigationButton';
import Navigation from '../../components/Navigation/Navigation';
import Alert from '../../components/Alert/Alert';
import DateBar from '../../components/DateBar';
import AppointmentConfirmationText from './AppointmentConfirmationText';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import { TicketContext } from '../../contexts/TicketContext';
import { TopSlotInfo } from '../../components/TopSlotInfo/TopSlotInfo';
import { LoadingImages } from '../../components/LoadingImages/LoadingImages';

import { usePost } from '../../helpers/hooks/usePost';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';
import { scrollToElement } from './AppointmentController';
import {
  selectedTopSlotIndexGA,
  sendAnalyticsDateSelected,
  sendReloadOnSchedulingError,
  successfullyBookedAppointmentGA,
} from '../../services/Analytics';
import TopSlotService from '../../services/TopSlotService';
import DateTime from '../../helpers/DateTime';

interface AppointmentWithTopSlotsProps {
  ticket: Ticket;
  stylesheet: Stylesheet;
  topSlots: SlotsDictionary;
  minDate: Date;
  maxDate: Date;
  highestSlot: TopSlot;
  onLoadMore: () => void;
  onSlotBooking: (pending: boolean) => void;
  areLoadingSlots: boolean;
  canRetrieveMore: boolean;
}

const AppointmentWithTopSlots: FC<AppointmentWithTopSlotsProps> = (props) => {
  const {
    ticket,
    stylesheet,
    topSlots,
    minDate,
    maxDate,
    highestSlot,
    onLoadMore,
    onSlotBooking,
    areLoadingSlots,
    canRetrieveMore,
  } = props;

  const [selectedTopSlot, setSelectedTopSlot] = useState<TopSlot | null>(highestSlot);
  const [selectedDate, setSelectedDate] = useState<string>(highestSlot.start.slice(0, 10));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { completeCurrentStep, navigateToNextStep } =
    useContext(StepsUpdateContext);
  const { fetchTicket, setShowFeedback } = useContext(TicketContext);

  const navigationButtonsRef = createRef<HTMLDivElement>();

  const { t } = useTranslation();
  const { response: { pending, error: isBookingError, complete: isBookingCompleted }, doRequest } = usePost();
  useScrollToTop();

  if (isBookingCompleted && !isBookingError) {
    completeCurrentStep();
    navigateToNextStep();
  }

  const handleNextClick = (): void => {
    if (!selectedTopSlot) {
      return;
    }

    const { hashId, workOrder: { appointment: { id } } } = ticket;
    setIsLoading(true);

    const request = new TopSlotService()
      .bookSlot(hashId, id, selectedTopSlot)
      .then(async () => {
        // This is needed so on the next screen (confirm) new required (arrivalWindowStart ...) fields are available right away
        await fetchTicket(hashId);
        successfullyBookedAppointmentGA(selectedTopSlot);
        // This is to only show the survey right after resident creates appoitnment or see noTopSlots.
        // If they revisit the confirmation page after a while they shouldnt be able to submit feedback
        setShowFeedback(true);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

    doRequest(request);
  };

  const selectDate = useCallback((date: string) => {
    if (selectedTopSlot && date !== selectedTopSlot.start.slice(0, 10)) {
      setSelectedTopSlot(null);
    } else {
      sendAnalyticsDateSelected(date);
    }
    setSelectedDate(date);
  }, [selectedTopSlot]);

  const selectSlot = useCallback((slot: TopSlot, index: number) => {
    setSelectedTopSlot(slot);
    selectedTopSlotIndexGA(index);
  }, []);

  useEffect(() => {
    onSlotBooking(pending);
  }, [onSlotBooking, pending]);

  if (pending) {
    return (
      <LoadingImages
        items={[
          {
            image: 'slotBooking',
            text: t('appointmentSection:bookingText'),
            description: t('appointmentSection:moment'),
            ariaLabel: t('appointmentSection:bookingText'),
          },
        ]}
      />
    );
  }

  return (
    <>
      {!!isBookingError && (
        <Alert
          text={t('alert:bookingError')}
          level="error"
          white
          textButton={{
            text: t('alert:refreshPage'),
            onClick: () => {
              sendReloadOnSchedulingError();
              setTimeout(() => {
                window.location.reload();
                // timeout to give GA a chance to send the event
              }, 100);
            },
          }} />
      )}

      <>
        <DateBar
          slots={topSlots}
          dates={DateTime.rangeDays(minDate, maxDate)}
          selectedDate={selectedDate}
          highestSlot={highestSlot}
          setSelectedDate={selectDate}
          onLoadMore={onLoadMore}
          canRetrieveMore={canRetrieveMore}
          areLoadingSlots={areLoadingSlots}
        />
        <div>
          {!!topSlots[selectedDate] && !areLoadingSlots && topSlots[selectedDate].map((topSlot: TopSlot, index: number) => (
            <TopSlotInfo
              key={index}
              isOptimal={topSlot.start === highestSlot.start}
              topSlot={topSlot}
              onSelect={(): void => {
                selectSlot(topSlot, index);
                scrollToElement(navigationButtonsRef.current);
              }}
              active={topSlot === selectedTopSlot}
            />
          ))}
        </div>
      </>
      {!!selectedTopSlot && (
        <AppointmentConfirmationText
          ticket={ticket}
          stylesheet={stylesheet}
          selectedTopSlot={selectedTopSlot}
        />
      )}

      <div className={styles.appointmentContainer}>
        <Navigation className={styles.fullButtonMobile} rootRef={navigationButtonsRef}>
          <NavigationButton
            text={t('appointmentSection:confirmAppointment')}
            nextDisabled={isLoading || areLoadingSlots || !selectedTopSlot}
            buttonAction="next"
            onClickOverride={handleNextClick}
          />
        </Navigation>
      </div>
    </>
  );
};

export default AppointmentWithTopSlots;
