import { InputHTMLAttributes, ReactElement } from 'react';


export const Input = (
  props: InputHTMLAttributes<HTMLInputElement | null>,
): ReactElement => (
  <input {...props} />
);

export const Textarea = (
  props: InputHTMLAttributes<HTMLTextAreaElement | null>,
): ReactElement => (
  <textarea {...props} />
);
