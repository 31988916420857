import classNames from 'classnames';

import { useFormatting } from '../../services/hooks/useFormatting';
import style from './Alert.module.scss';
import { FCWithOptionalChild } from '../../interfaces/Shared';
import MaterialIcon, { IconNames } from '../MaterialIcon/MaterialIcon';

interface AlertProps {
  text: string;
  icon?: IconNames;
  level?: 'error' | 'warn' | 'info' | 'success';
  textButton?: { text: string; onClick: () => void };
  className?: string;
  white?: true;
}

const Alert: FCWithOptionalChild<AlertProps> = ({ text, level, icon = 'info', textButton, children, white, className }) => {
  const { formattedText: alertText } = useFormatting(text);

  const alertClassNames = classNames([
    style.container,
    style[`${level}Background`],
    white && style.whiteBg,
    level === 'info' && style.withShadow,
    className,
  ]);

  const iconClassNames = classNames(
    style.icon,
    level ? style[`${level}Icon`] : '');

  return (
    <div className={alertClassNames}>
      <div className={classNames(style.verticalLine, style[`${level}VerticalLine`])}/>
      {!!icon && <MaterialIcon name={icon} className={iconClassNames} />}
      <div className={style.content}>
        {!!alertText && <span className={style.alertText} dangerouslySetInnerHTML={{ __html: alertText }} />}
        {!!textButton && (
          <button
            className={style.alertTextButton}
            onClick={textButton.onClick}
            aria-label={textButton.text}
          >
            {textButton.text}
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Alert;
