import { ReactElement, RefObject, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import style from './Carousel.module.scss';
import Button from '../Button';

interface ArrowProps {
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  ariaLabel?: string;
}

interface CarouselProps<T> {
  carouselRef: RefObject<HTMLDivElement | null>;
  data: T[] | null;
  leftArrow: ArrowProps;
  rightArrow: ArrowProps;
  extraChild?: ReactElement | null;
  renderItem: (item: T, index: unknown) => ReactElement;
}

const Carousel = <T, >({
  data,
  carouselRef,
  leftArrow,
  rightArrow,
  extraChild = null,
  renderItem,
}: CarouselProps<T>) => {
  const [isScrolledRight, setIsScrolledRight] = useState(false);

  const setIsScrolledRightDebounce = useMemo(
    () => debounce((isScrolledRight: boolean) => setIsScrolledRight(isScrolledRight), 16),
    [setIsScrolledRight]
  );

  return (
    <div className={style.carousel}>
      <Button
        className={style.arrowButton}
        icon={{ name: 'arrow_back_ios' }}
        onClick={leftArrow.onClick}
        disabled={!isScrolledRight || leftArrow.disabled}
        ariaLabel={leftArrow.ariaLabel}
      />
      <div
        role="tablist"
        className={style.contentItems}
        ref={carouselRef}
        onScroll={((event) => {
          setIsScrolledRightDebounce(event.currentTarget.scrollLeft !== 0);
        })}
        tabIndex={0}
      >
        {!!data && !!data.length && data.map((item, index) => renderItem(item, index))}
        {extraChild}
      </div>
      <Button
        className={style.arrowButton}
        isLoading={rightArrow.isLoading}
        disabled={rightArrow.disabled}
        icon={{ name: 'arrow_forward_ios' }}
        onClick={rightArrow.onClick}
        ariaLabel={rightArrow.ariaLabel}
      />
    </div>
  );
};

export default Carousel;
