import { ChangeEvent, FC, Ref } from 'react';
import classNames from 'classnames';

import { Input, Textarea } from './FormInputs';
import style from './Form.module.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

export type FormChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

const fieldTypes: Record<'text' | 'textarea', React.ElementType> = {
  text: Input,
  textarea: Textarea,
};

export type FormElementProps ={
  value: string;
  name: string;
  label: string;
  disabled?: boolean;
  split?: boolean;
  error?: string;
  onChange?: (event: FormChangeEvent) => void;
  fieldClassName?: string;
} & (TextFields | TextareaFields);

type TextFields = {
  type: 'text';
  ref?: Ref<HTMLInputElement | null>;
};

type TextareaFields = {
  type: 'textarea';
  ref?: Ref<HTMLTextAreaElement | null>;
};

const FormElement: FC<FormElementProps> = ({
  type, name, value, onChange, label, disabled, split, error,
  fieldClassName = '', ref
}) => {
  const Component = fieldTypes[type];

  const formGroupClassNames = classNames({
    [style.formGroup]: true,
    [style.split]: split,
    [style.labelSpacing]: true,
    'partner-border-color': !error,
    'background-color-onfocus-dropshadow': !error
  });

  const labelClassNames = classNames({
    [style.label]: true,
    [style.labelVisible]: value,
    [style.labelError]: error
  });

  return (
    <div className={formGroupClassNames}>
      <label htmlFor={name} className={labelClassNames}>{label}</label>
      <Component
        ref={ref}
        name={name}
        className={classNames({
          [style.error]: error,
          [style.filled]: !!value && !disabled && !error,
          [fieldClassName]: !!fieldClassName,
        })}
        value={value}
        disabled={disabled}
        placeholder={label}
        onChange={disabled ? undefined : onChange}
      />
      {!!error && <MaterialIcon className={style.errorIcon} size={20} name="error" />}
      {!!error && <p className={style.msgError}>{error}</p>}
    </div>

  );
};

export default FormElement;
