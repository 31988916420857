import { FC, ReactElement } from 'react';

import { Product } from '../../../../interfaces/Signature';
import styles from './ProductItem.module.scss';
import MoneyService from '../../../../services/MoneyService';

const ProductItem: FC<Product> = ({ quantity, name, price }): ReactElement => {
  return (
    <li className={styles.productList}>
      <span>{quantity}</span>
      <span className={styles.productName}>{name}</span>
      {!!price && <span className={styles.price}>{MoneyService.format(price)}</span>}
    </li>
  );
};

export default ProductItem;
