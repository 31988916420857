import { FC, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SlotsDictionary, TopSlot } from '../../interfaces/TopSlot';
import { DateButton } from './DateButton';
import { LoadMoreSlots } from './LoadMoreSlots';
import Carousel from '../Carousel/Carousel';

import { useHorizontalScrollDirection } from '../../helpers/hooks/useHorizontalScrollDirection';
import { sendDatebarLoadMore } from '../../services/Analytics';

interface DateBarProps {
  slots: SlotsDictionary;
  highestSlot: TopSlot;
  dates: string[];
  selectedDate: string;
  areLoadingSlots: boolean;
  canRetrieveMore: boolean;
  onLoadMore: () => void;
  setSelectedDate: (date: string) => void;
}

interface PaginatedDate {
  dateIndex: number;
  pageIndex: number;
  date: string;
  isDisabled: boolean;
  containsHighestSlot: boolean;
  isSelected: boolean;
}

type ScrollDirection = 'left' | 'right';

const ITEMS_TO_SCROLL = 7;

const DateBar: FC<DateBarProps> = ({
  slots,
  dates,
  selectedDate,
  areLoadingSlots,
  canRetrieveMore,
  highestSlot,
  onLoadMore,
  setSelectedDate,
}) => {
  const datebarRef: RefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const shouldAutoNavigateToNextPage = useRef(false);

  const { t } = useTranslation();

  useHorizontalScrollDirection(datebarRef);

  const paginatedDates: PaginatedDate[] = useMemo(() => dates.map((date, index) => ({
    dateIndex: index,
    pageIndex: Math.floor(index / ITEMS_TO_SCROLL),
    date,
    isDisabled: !slots[date],
    containsHighestSlot: date === highestSlot.start.slice(0, 10),
    isSelected: selectedDate === date,
  })), [dates, selectedDate, highestSlot, slots]);

  const handleLoadMore = useCallback(() => {
    sendDatebarLoadMore();
    onLoadMore();
  }, [onLoadMore]);

  const handleArrowClick = useCallback((direction: ScrollDirection): void => {
    const selectedPageIndex = paginatedDates.find((date) => date.isSelected);
    if (!datebarRef.current) return;

    const currentSelectedPageIndex = selectedPageIndex?.pageIndex ?? 0;
    const maxPageIndex = paginatedDates[paginatedDates.length - 1].pageIndex;

    let newPageIndex = currentSelectedPageIndex;

    if (direction === 'left') {
      newPageIndex = Math.max(0, currentSelectedPageIndex - 1);
    } else {
      if (currentSelectedPageIndex < maxPageIndex) {
        newPageIndex = currentSelectedPageIndex + 1;
      } else {
        if (!canRetrieveMore || shouldAutoNavigateToNextPage.current) return;
        shouldAutoNavigateToNextPage.current = true;
        handleLoadMore();
        return;
      }
    }

    // finds the first date of the page with the highest slot
    const dateWithHighestSlot = paginatedDates.find((date) => date.containsHighestSlot && date.pageIndex === newPageIndex);
    // finds the first date of the page with the highest slot
    const dateWithSlots = paginatedDates.find((date) => date.pageIndex === newPageIndex && !date.isDisabled);
    // finds the first date of the new page
    const dateOfTheSelectedWeek = paginatedDates.find((date) => date.pageIndex === newPageIndex);

    if (!dateOfTheSelectedWeek) return; // safety check

    const element = datebarRef.current.children[dateOfTheSelectedWeek.dateIndex];

    if (element) {
      const rect = element.getBoundingClientRect();
      const parentRect = datebarRef.current.getBoundingClientRect();
      datebarRef.current.scrollTo({ left: rect.left - parentRect.left - 4 + datebarRef.current.scrollLeft, behavior: 'smooth' });
    }

    setSelectedDate(dateWithHighestSlot?.date ?? dateWithSlots?.date ?? dateOfTheSelectedWeek.date);
  }, [paginatedDates, handleLoadMore, canRetrieveMore, setSelectedDate]);

  useEffect(() => {
    if (!areLoadingSlots && shouldAutoNavigateToNextPage.current) {
      const currentSelectedPage = paginatedDates.find((date) => !!date.isSelected);

      if (currentSelectedPage && currentSelectedPage.pageIndex < paginatedDates[paginatedDates.length - 1].pageIndex) {
        handleArrowClick('right');
        shouldAutoNavigateToNextPage.current = false;
      }
    }
  }, [areLoadingSlots, paginatedDates, handleArrowClick]);

  const lastPossiblePage = paginatedDates.find((date) => date.pageIndex === paginatedDates[paginatedDates.length - 1].pageIndex);

  return (
    <Carousel
      data={paginatedDates}
      carouselRef={datebarRef}
      leftArrow={{
        onClick: () => handleArrowClick('left'),
        ariaLabel: t('slots:showPreviousSlots'),
        disabled: areLoadingSlots,
      }}
      rightArrow={{
        onClick: () => handleArrowClick('right'),
        isLoading: areLoadingSlots,
        disabled: !canRetrieveMore && lastPossiblePage?.isSelected,
        ariaLabel: t('slots:showNextSlots'),
      }}
      renderItem={(paginatedDay) => (
        <DateButton
          key={paginatedDay.date}
          slot={paginatedDay.date}
          selected={paginatedDay.isSelected}
          disabled={paginatedDay.isDisabled || areLoadingSlots}
          onSelect={setSelectedDate}
        />
      )}
      extraChild={canRetrieveMore ? (
        <LoadMoreSlots
          loadMoreSlots={handleLoadMore}
          loading={areLoadingSlots}
        />
      ) : null}
    />
  );
};

export default DateBar;
