import { FC, ReactElement, useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Signature.module.scss';
import { Signature, SignatureImageUploadBody } from '../../interfaces/Signature';

import Button from '../../components/Button';
import ProductsSection from './components/ProductsSection/ProductsSection';
import CompletedSignature from './components/CompletedSignature/CompletedSignature';
import SignatureCanvas from './components/SignatureCanvas/SignatureCanvas';
import Alert from '../../components/Alert/Alert';
import CheckBoxInput from '../../components/CheckBoxInput/CheckBoxInput';

import usePageTitle from '../../services/hooks/usePageTitle';
import { sendSignatureDetailsUpdated } from '../../services/Analytics';
import { generateSignedPostPayload } from '../../services/Aws';
import MoneyService from '../../services/MoneyService';
import SignatureService from '../../services/SignatureService';
import { SignatureOutletContext } from '../../services/Outlet';


const SignatureComponent: FC = (): ReactElement | null => {
  const { t } = useTranslation();
  const { signature: defaultSignature } = useOutletContext<SignatureOutletContext>();

  const [signature, setSignature] = useState<Signature | undefined>(defaultSignature);
  const [customerAgreedToSign, setCustomerAgreedToSign] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInErrorMode, setIsInErrorMode] = useState<boolean>(false);
  const [image, setImage] = useState<SignatureImageUploadBody['file'] | null>(null);

  usePageTitle(t('pageTitle:signature'));

  const onSave = useCallback(async (signature: Signature) => {
    const signatureService = new SignatureService();
    const signatureUpdateParams = { completed: true, customerSigned: customerAgreedToSign };
    setIsLoading(true);
    try {
      if (customerAgreedToSign && image) {
        const body = {
          ...signature.presignedUploadFields,
          file: image,
        };
        const payload = generateSignedPostPayload(body);
        await signatureService.uploadImage({
          presignedUploadUrl: signature.presignedUploadUrl,
          body: payload
        });
      }
      await signatureService.updateSignature({ hashId: signature.hashId, ...signatureUpdateParams });

      !isInErrorMode && sendSignatureDetailsUpdated({ customerSigned: customerAgreedToSign, imageSize: image?.size, });
      setSignature({ ...signature, ...signatureUpdateParams });
    } catch (error) {
      setIsInErrorMode(true);
    }
    setIsLoading(false);
  }, [image, customerAgreedToSign, setSignature, isInErrorMode]);

  const onImageChange = useCallback((image: SignatureImageUploadBody['file'] | null) => {
    setImage(image);
    setIsInErrorMode(false);
  }, []);

  if (!signature) {
    return null;
  }

  const {
    customerSigned, completed, productsConsumed,
    expertComment, productsReturned, totalPrice,
    customerApprovalText
  } = signature;

  if (completed) {
    return (
      <CompletedSignature customerSigned={customerSigned} />
    );
  }

  return (
    <div className={styles.signatureContainer}>
      <h1>{t('signature:title')}</h1>
      <ProductsSection title={t('signature:productsConsumed')} data={productsConsumed} />
      {!!productsReturned.length && (
        <ProductsSection title={t('signature:productsReturned')} data={productsReturned} />
      )}
      <div className={styles.totalPriceContainer}>
        <label>{t('signature:total')} <span className={styles.vatLabel}>{t('signature:includingVat')}</span></label>
        <span>{MoneyService.format(totalPrice)}</span>
      </div>
      <div className={styles.separator}></div>

      <div className={styles.customerInformationSection}>
        <h2>{t('signature:customerInformation')}</h2>
        <p className={styles.expertNoteTitle}>{t('signature:expertComment')}</p>
        <p className={styles.expertNote}>{expertComment}</p>
      </div>

      {!!customerApprovalText && (
        <div className={styles.customerTermsSection}>
          <h2>{t('signature:customerTerms')}</h2>
          <p>{customerApprovalText}</p>
        </div>
      )}

      <h2>{t('signature:signVisit')}</h2>

      {!!isInErrorMode && (
        <Alert text="Something went wrong" level="error" />
      )} {/* TODO: Error handling message when PUT and PATCH methods fail */}

      <SignatureCanvas
        onClear={() => onImageChange(null)}
        onDraw={(image) => onImageChange(image)}
        disabled={!customerAgreedToSign}
      />

      <CheckBoxInput
        id="correct"
        label={t('signature:declineSign')}
        isChecked={!customerAgreedToSign}
        onCheck={(isChecked) => {
          setIsInErrorMode(false);
          setCustomerAgreedToSign(!isChecked);
          if (customerAgreedToSign && image) {
            setImage(null);
          }
        }}
      />

      <div className={styles.buttonContainer}>
        <Button
          disabled={(!image && customerAgreedToSign) || isLoading}
          text={t('shared:agree')}
          onClick={() => onSave(signature)}
        />
      </div>
    </div>
  );
};

export default SignatureComponent;
