import { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSignatureCanvas from 'react-signature-canvas';
import classNames from 'classnames';

import { SignatureImageUploadBody } from '../../../../interfaces/Signature';
import styles from './SignatureCanvas.module.scss';
import IconButton from '../../../../components/IconButton/IconButton';

interface SignatureCanvasProps {
  onDraw: (image: SignatureImageUploadBody['file'] | null) => void;
  onClear: () => void;
  disabled?: boolean;
}

const SignatureCanvas: FC<SignatureCanvasProps> = ({ onDraw, onClear, disabled }): ReactElement | null => {
  const { t } = useTranslation();
  const [isCanvasActive, setIsCanvasActive] = useState<boolean>(false);
  const canvasRef = useRef<ReactSignatureCanvas | null>(null);
  const containerStyle = classNames(styles.canvasContainer, (isCanvasActive && !disabled) && ['partner-border-font-color']);

  const handleOnDrawBegin = useCallback(() => {
    if (canvasRef.current) {
      canvasRef.current.getCanvas().toBlob(onDraw, 'image/png');
    }
  }, [onDraw]);

  const onClearHanler = useCallback(() => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      onClear();
    }
  }, [onClear]);

  return (
    <div className={containerStyle}>
      {(!isCanvasActive || disabled) ? (
        <button className={styles.canvasOverlayButton} onClick={() => setIsCanvasActive(true)}>
          {t('signature:activateSignature')}
        </button>
      ) : (
        <>
          <ReactSignatureCanvas
            ref={canvasRef}
            throttle={0}
            onEnd={handleOnDrawBegin}
            clearOnResize={false}
            canvasProps={{
              style: {
                width: '100%',
              }
            }}
          />
          <IconButton
            icon="undo"
            className={styles.undoButton}
            onClick={onClearHanler}
          />
        </>
      )}
    </div>
  );
};

export default SignatureCanvas;
