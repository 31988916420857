import { useEffect, useState } from 'react';
import { Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { Sidetab } from '@typeform/embed-react';

import { TicketProvider } from '../../contexts/TicketContext';
import { StylesheetProvider } from '../../contexts/StylesheetContext';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { StepsProvider } from '../Steps/StepsContext';
import FadeInOutWrapper from '../FadeInOutWrapper/FadeInOutWrapper';
import classNames from 'classnames';
import { TicketOutletContext } from '../../services/Outlet';
import StepsService from '../../services/StepsService';
import { PageGuard } from '../PageGuard/PageGuard';
import { useMaintenanceGuard } from '../../helpers/hooks/useMaintenanceGuard';
import { hideSpinner } from '../../services/Spinner';

export const TicketOutlet = () => {
  const [sideTabId, setSideTabId] = useState<string | undefined>(undefined);
  const loaderData = useLoaderData() as TicketOutletContext;

  const { ticket, activeStylesheet, brandStylesheet, globalStylesheet, isInMaintenanceMode } = loaderData;
  const serviceAppointmentId = ticket?.workOrder.appointment?.id || '';

  hideSpinner();
  useMaintenanceGuard(isInMaintenanceMode);

  useEffect(() => {
    setSideTabId(activeStylesheet?.settings.feedbackSidetab?.id);
  }, [activeStylesheet]);

  const { pathname } = useLocation();
  const pagesWithFullScreenMinHeight = ['questions', 'contact'];
  const pagesWithWhiteBackground = ['customer'];

  const matchPathname = (toMatch: string) => {
    return new RegExp(`/[a-zA-Z0-9]+/${toMatch}`).test(pathname);
  };

  const pageClasses = classNames(
    'page',
    pagesWithWhiteBackground.some(matchPathname) && 'whiteBackground',
    !StepsService.showSteps(pathname) && 'pageWithoutStepper',
    pagesWithFullScreenMinHeight.some(matchPathname) && 'min-height-fullscreen'
  );

  return (
    <>
      <TicketProvider defaultTicket={ticket}>
        <StylesheetProvider globalStylesheet={globalStylesheet} brandStylesheet={brandStylesheet}>
          <StepsProvider>
            <PageGuard>
              <Header />
              <main className={pageClasses}>
                <FadeInOutWrapper isEnabled>
                  <div className="container">
                    <Outlet context={loaderData} />
                  </div>
                </FadeInOutWrapper>
              </main>
              <Footer />
            </PageGuard>
          </StepsProvider>
        </StylesheetProvider>
      </TicketProvider>
      {!!sideTabId && !!serviceAppointmentId && (
        <Sidetab
          id={sideTabId}
          width={320}
          autoClose
          buttonText="Feedback"
          hidden={{
            service_appointment_id: serviceAppointmentId
          }}
        />
      )}
    </>
  );
};
