import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './LoadMoreSlots.module.scss';
import { handleKeyboardSelect } from '../../helpers/Accessibility';

interface LoadMoreSlotsProps {
  loadMoreSlots?: () => void;
  loading: boolean;
}

export const LoadMoreSlots: FC<LoadMoreSlotsProps> = ({ loadMoreSlots, loading }) => {
  const { t } = useTranslation();

  const loaderClassNames = classnames(style.slotsLoader, 'partner-font-color');

  const classes = classnames({
    [style.loadMoreSlots]: true,
    'partner-border-font-color': true,
  });

  const handleClick = useCallback(() => {
    if (!loading && loadMoreSlots) loadMoreSlots();
  }, [loading, loadMoreSlots]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(handleClick), [handleClick]);

  return (
    <div role="button" className={classes} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0}>
      <span>{t('slots:loadMoreSlots')}</span>
      {!!loading && <div className={loaderClassNames}/>}
    </div>
  );
};
