import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TopSlot } from '../../interfaces/TopSlot';
import styles from './TopSlotInfo.module.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

import { getEcoRating } from '../../helpers/CalculateRating';
import { handleKeyboardSelect } from '../../helpers/Accessibility';
import DateTime from '../../helpers/DateTime';

interface TopSlotInfoProps {
  topSlot: TopSlot;
  isOptimal?: boolean;
  onSelect: () => void;
  active: boolean;
}

export const TopSlotInfoComponent: FC<TopSlotInfoProps> = ({
  topSlot: { start, end, grade },
  isOptimal,
  active,
  onSelect,
}) => {
  const { t } = useTranslation();
  const timeStart = DateTime.getTimeStringFromDate(
    DateTime.roundToNearestMinutes(start)
  );
  const timeEnd = DateTime.getTimeStringFromDate(
    DateTime.roundToNearestMinutes(end)
  );

  const handleKeyDown = useMemo(
    () => handleKeyboardSelect(onSelect),
    [onSelect]
  );

  let slotAriaLabel = t('slots:ariaLabelSlot', {
    startTime: timeStart,
    endTime: timeEnd,
    grade: getEcoRating(grade).toString(),
  });

  if (isOptimal) {
    slotAriaLabel += `. ${t('slots:bestSlot')}`;
  }

  return (
    <div className={styles.buttonWrapper}>
      <button
        className={classNames(
          styles.slot,
          { active },
          'partner-highlight-border',
          active && 'partner-initial-border-color-thin'
        )}
        onClick={onSelect}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-label={slotAriaLabel}
        aria-current={active}
      >
        <div className={classNames(styles.slotWrapper)}>
          <div className={styles.slotInfo}>
            <div>
              {active ? (
                <span className={styles.selected}>
                  <div className={styles.radioCenter} />
                </span>
              ) : (
                <span className={styles.selector} />
              )}
            </div>
            <p className={styles.date}>
              {` ${t('appointmentSection:separator')} `}
              <span>{`${timeStart} - ${timeEnd} ${t(
                'appointmentSection:hour'
              )}`}</span>
            </p>
            {!!isOptimal && (
              <div className={styles.route}>
                <img
                  src="/assets/greenOption.png"
                  alt="greenOption"
                  className={styles.routeIcon}
                />
              </div>
            )}
          </div>
          {!!isOptimal && (
            <div className={classNames(styles.description)}>
              {t('slots:bestSlot')}
              <MaterialIcon name="eco" />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

export const TopSlotInfo = memo(TopSlotInfoComponent);
