import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CompletedSignature.module.scss';

interface CompletedSignatureProps {
  customerSigned: boolean;
}

const CompletedSignature: FC<CompletedSignatureProps> = ({ customerSigned }): ReactElement => {
  const { t } = useTranslation();
  const label = customerSigned ? 'accepted' : 'declined';
  const acceptedImage = '/assets/accepted.png';
  const declinedImage = '/assets/declined.png';

  return (
    <div className={styles.completedSignatureContainer}>
      <img src={customerSigned ? acceptedImage : declinedImage} width={155} height={122} alt={label} />
      <h3>{t(`signature:completedSignature:${label}:title`)}</h3>
      <p>{t(`signature:completedSignature:${label}:description`)}</p>
    </div>
  );
};

export default CompletedSignature;
