import { FC, HTMLAttributes, StyleHTMLAttributes } from 'react';
import classNames from 'classnames';

export type IconNames = 'expand_more' | 'room' | 'undo' | 'error' | 'access_time' | 'info' | 'calendar_today' | 'calendar_month'
| 'eco' | 'push_pin' | 'error_outline' | 'check_circle' | 'paper' | 'star' | 'phone' | 'wysiwyg' | 'search' | 'add'
| 'arrow_forward_ios' | 'arrow_back_ios' | 'info_fill' | 'close' | 'schedule' | 'location_on' | 'mail' | 'event_note';

interface IconProps {
  name: IconNames;
  color?: string;
  isPartnerFontColor?: boolean;
  ariaHidden?: boolean;
  className?: string;
  size?: number;
  style?: StyleHTMLAttributes<HTMLAttributes<HTMLElement>['style']>;
}

const MaterialIcon: FC<IconProps> = ({ name, ariaHidden, className, isPartnerFontColor, style, size, color }) => {
  const iconStyles = classNames('material-icons', className, {
    'partner-font-color': !!isPartnerFontColor && !color,
  });

  const dynamicStyles: HTMLAttributes<HTMLElement>['style'] = {
    ...(style || {}),
    ...(size ? { fontSize: size } : undefined),
    ...(color ? { color: color } : undefined),
    ...(name === 'arrow_back_ios' ? { paddingLeft: 8 } : undefined), // This check centers back arrow icon to the center
  };

  return (
    <i aria-hidden={ariaHidden || true} style={dynamicStyles} className={iconStyles}>{name}</i>
  );
};

export default MaterialIcon;
