import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Ticket } from '../../interfaces/Ticket';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { TopSlot } from '../../interfaces/TopSlot';
import styles from './Appointment.module.scss';

import Alert from '../../components/Alert/Alert';

import { useFormatting } from '../../services/hooks/useFormatting';
import DateTime from '../../helpers/DateTime';

interface AppointmentConfirmationTextProps {
  ticket: Ticket;
  stylesheet: Stylesheet;
  selectedTopSlot: TopSlot;
}

const AppointmentConfirmationText: FC<AppointmentConfirmationTextProps> = ({ ticket, stylesheet, selectedTopSlot }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { appointmentSection } = stylesheet;
  const { workOrder: { appointment } } = ticket;
  const { start, end } = selectedTopSlot;

  const isMigrationTicket = ticket.isMigration
    && (new Date(start) > new Date(appointment.firstPossiblePlanDate)
    || appointmentSection.migrationWarningShowOnPageLoad);
  const isRescheduling = pathname === '/change';
  const hasWarningText = appointmentSection.migrationWarningText
    && (!isRescheduling || (isRescheduling && !appointmentSection.migrationWarningChangeText));

  const { formattedText: migrationWarningText } = useFormatting(
    appointmentSection.migrationWarningText,
    { replaceDate: appointment.firstPossiblePlanDate }
  );
  const { formattedText: migrationWarningChangeText } = useFormatting(
    appointmentSection.migrationWarningChangeText,
    { replaceDate: appointment.firstPossiblePlanDate, }
  );

  return (
    <div className={styles.appointmentConfirmation}>
      <table>
        <thead>
          <tr>
            <th className={styles.appointmentConfirmationHeader}>{t('slots:selectedSlots')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('shared:date')}</td>
            <td>{DateTime.formatDate('EEEE dd MMMM', new Date(start))}</td>
          </tr>
          <tr>
            <td>{t('shared:time')}</td>
            <td>{t('slots:selectedAppointmentTimeRange', {
              startTime: DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(start)),
              endTime: DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(end))
            })}
            </td>
          </tr>
        </tbody>
      </table>

      {!!isMigrationTicket && !!hasWarningText && (
        <Alert
          white
          text={migrationWarningText}
          level="warn"
        />
      )}
      {!!isMigrationTicket && !!isRescheduling && !!appointmentSection.migrationWarningChangeText && (
        <Alert
          white
          text={migrationWarningChangeText}
          level="warn"
        />
      )}
    </div>
  );
};

export default AppointmentConfirmationText;
