import { createContext, useCallback, useEffect, useState } from 'react';

import { BusinessReviews } from '../interfaces/BusinessReviews';
import BusinessReviewsService from '../services/BusinessReviewsService';
import { FCWithChild } from '../interfaces/Shared';

export interface BusinessReviewsProviderStore {
  businessReviews: BusinessReviews | undefined;
}

export const BusinessReviewsContext = createContext({} as BusinessReviewsProviderStore);

const BusinessReviewsProvider: FCWithChild = ({ children }) => {
  const [businessReviews, setBusinessReviews] = useState<BusinessReviews | undefined>(undefined);

  const getReviews = useCallback(async (): Promise<void> => {
    const businessReviews = await new BusinessReviewsService().getBusinessReviews().catch(() => undefined);

    if (businessReviews) {
      setBusinessReviews(businessReviews);
    }
  }, [setBusinessReviews]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <BusinessReviewsContext value={{ businessReviews }}>
      {children}
    </BusinessReviewsContext>
  );
};

export { BusinessReviewsProvider };

export const BusinessReviewsConsumer = BusinessReviewsContext.Consumer;
