import { createContext, useCallback, useState } from 'react';

import { Ticket } from '../interfaces/Ticket';
import { FCWithChild } from '../interfaces/Shared';

import TicketService from '../services/TicketService';
import { Customer } from '../interfaces/Customer';

export interface TicketProviderStore {
  ticket: Ticket | undefined;
  isInErrorMode: boolean;
  showFeedback: boolean;
  setTicket: (ticket: Ticket | undefined) => void;
  fetchTicket: (id: string) => Promise<Ticket | undefined>;
  setIsInErrorMode: (isInErrorMode: boolean) => void;
  setShowFeedback: (showFeedback: boolean) => void;
  customer: Customer | undefined;
  setCustomer: (customer: Customer) => void;
}

export const TicketContext = createContext({} as TicketProviderStore);

interface TicketProviderProps {
  defaultTicket?: Ticket;
}

const TicketProvider: FCWithChild<TicketProviderProps> = ({ children, defaultTicket }) => {
  const [ticket, setTicket] = useState<Ticket | undefined>(defaultTicket);
  const [isInErrorMode, setIsInErrorMode] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const fetchTicket = useCallback(async (ticketId: string): Promise<Ticket | undefined> => {
    const ticket = await new TicketService().getTicket(ticketId).catch(() => undefined);

    if (ticket) {
      setTicket(ticket);
    }

    return ticket;
  }, []);

  const setCustomer = useCallback((customer: Customer) => {
    if (ticket) {
      setTicket({ ...ticket, customer });
    }
  }, [ticket]);

  const store: TicketProviderStore = {
    ticket,
    isInErrorMode,
    showFeedback,
    fetchTicket,
    setTicket,
    setIsInErrorMode,
    setShowFeedback,
    customer: ticket?.customer,
    setCustomer
  };

  return (
    <TicketContext value={store}>
      {children}
    </TicketContext>
  );
};

export { TicketProvider };

export const TicketConsumer = TicketContext.Consumer;
